
import { Vue, Options } from "vue-class-component";
import { reactive } from "vue";
import Toaster from "../../helpers/Toaster";
import UserAuthentication from "../../service/UserAuthentication";
import router from "../../router";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

@Options({
  components: {},
  title: "Medria Login",
})
export default class Login extends Vue {
  private showProgress = false;
  private toast;
  private submitted = false;
  private state = reactive({
    storeName: "",
    storeCode: "0001",
    address: "",
    userName: "",
    userEmail: "",
    password: "",
    //purchaseCode: "d5a574c7-8f44-4608-88b1-ea6396ca5c14"
    purchaseCode: ""
  });

  private rules = {
    userEmail: {
      required,
      email,
    },
    storeName: {
      required,
    },
    storeCode: {
      required,
    },
    address: {
      required,
    },
    userName: {
      required,
    },
    password: {
      required,
    },
    purchaseCode: {
      required,
    }
  };

  private v$ = useVuelidate(this.rules, this.state);

  created() {
    this.toast = new Toaster();
  }

  mounted()
  {
    this.checkNewInstallation();
  }

  installApp(isFormValid) {

    this.submitted = true;
    
    if (isFormValid) {

        const auth = new UserAuthentication();
        this.showProgress = true;

        auth.installApp(
          this.state
        ).then((res) => {
          if(res == 'exists')
          {
            this.toast.showInfo('Sorry app already installed');
          }
          else if(res == 'success')
          {
            this.clearItems();
            this.toast.showSuccess('App installed successfully');
            router.replace({ path: "/login", params: {} });
          }
          else if(res == 'failed')
          {
            this.toast.showError('Sorry cannot install the app');
          }
          else
          {
            this.toast.showError(res);
          }
          
          this.submitted = false;
          this.showProgress = false;
        });
    }
  }

  clearItems()
  {
    this.state.storeName = '';
    this.state.storeCode = '';
    this.state.address = '';
    this.state.userName = '';
    this.state.userEmail = '';
    this.state.password = '';
    this.state.purchaseCode = '';
  }

  checkNewInstallation()
  {
      const auth = new UserAuthentication();  
        
      auth.checkNewInstallation().then((res) => {
          if(res)
          {
            router.replace({ path: "/login", params: {} });     
          }
      });
  }
}
