
import { Vue, Options } from "vue-class-component";
import { reactive } from "vue";
import Toaster from "../../helpers/Toaster";
import { useStore, ActionTypes } from "../../store";
import UserAuthentication from "../../service/UserAuthentication";
import router from "../../router";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

@Options({
  components: {},
  title: "Medria Login",
})
export default class Login extends Vue {
  private showProgress = false;
  private toast;
  private submitted = false;
  private state = reactive({
    email: "",
    password: "",
  });

  private rules = {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  };

  private v$ = useVuelidate(this.rules, this.state);

  created() {
    this.toast = new Toaster();
  }

  mounted() {
    //this.checkNewInstallation();
  }

  checkNewInstallation() {
    const auth = new UserAuthentication();

    auth.checkNewInstallation().then((res) => {
      if (!res) {
        router.replace({ path: "/install", params: {} });
      }
    });
  }

  authenticate(isFormValid) {
    this.submitted = true;

    if (isFormValid) {
      const auth = new UserAuthentication();
      const store = useStore();
      this.showProgress = true;

      auth
        .loginUser(this.state.email.trim(), this.state.password.trim(), "Web")
        .then((res) => {
          if (typeof res !== "undefined") {
            this.toast.showSuccess("Greetings " + res.userName);
            store.dispatch(ActionTypes.AUTH_REQUEST, res.token);
            store.dispatch(ActionTypes.GET_CURRENCY, res.currency);
            store.dispatch(ActionTypes.PERMISSION_LIST, res.permissionList);
            router.replace({ path: "/store/dashboard", params: {} });
          } else {
            this.state.email = "";
            this.state.password = "";
            this.submitted = false;
          }
        });
    }

    this.showProgress = false;
  }

  loadUser(role) {
    if (role == "admin") {
      this.state.email = "medria@pankhudisoftware.com";
      this.state.password = "123456";
    }
  }
}
